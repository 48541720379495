import {Component, OnDestroy, OnInit} from '@angular/core';
import {StoreProfile} from '../../../domain/models/store/store-profile';
import {Cart} from '../../../domain/models/order/cart';
import {ActivatedRoute, Router} from '@angular/router';
import {StoreService} from '../../../domain/store.service';
import {CartService} from '../../../domain/cart.service';
import {CampaignOrderLine, OrderLineType, ProductOrderLine} from '../../../domain/models/order/order-line';
import {Store} from '../../../domain/models/store/store';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialog} from '../../../dialogs/confirm/confirm.dialog';
import {TranslateService} from '@ngx-translate/core';
import {PriceUtils} from '../../../utils/price.utils';
import {PaymentIdentifier, PaymentMethod} from '../../../domain/models/payment/payment-method';
import {PaymentService} from '../../../domain/payment.service';
import {ForegroundPaths} from '../../../app-routing.module';
import {AmountsheetDialog} from '../../../dialogs/actionsheet/amountsheet/amountsheet.dialog';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {ShipmentsheetDialog} from '../../../dialogs/actionsheet/shipmentsheet/shipmentsheet.dialog';
import {CartUtils} from '../../../utils/cart.utils';
import {ShippingAddress} from '../../../domain/models/order/shipping-address';
import {FulfillmentOption} from '../../../domain/models/store/fulfillmentOption';
import {ProductService} from '../../../domain/product.service';
import {Paginated} from '../../../transport/models/paginated';
import {Product} from '../../../domain/models/product/product';
import {AdjustmentType} from '../../../domain/models/product/adjustmentType';
import {AdjustmentTypeDialog} from '../../../dialogs/adjustment-type/adjustment-type-dialog';
import {OfflineCart} from '../../../domain/models/order/offline-cart';
import {OfflineCartService} from '../../../domain/offline-cart.service';
import {OfflineProductOrderLine} from '../../../domain/models/order/offline-product-order-line';
import {QuantityConfirmDialog} from '../../../dialogs/quantity-confirm/quantity-confirm.dialog';
import AdyenCheckout from '@adyen/adyen-web';
import {AdyenSession, InitializePaymentResponse} from '../../../transport/models/payment/initialize-payment.response';
import {MessageDialog} from '../../../dialogs/message/message.dialog';
import {ScannerService} from '../../../domain/scanner.service';
import {AdjustmentTypeService} from '../../../domain/adjustment-type.service';
import {OrderService} from '../../../domain/order.service';
import {CustomerService} from '../../../domain/customer.service';
import {delay} from '../../../utils/promise.utils';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-cart',
  templateUrl: './cart-details.component.html',
  styleUrls: ['./cart-details.component.sass']
})
export class CartDetailsComponent implements OnInit, OnDestroy {
  cart?: Cart;
  offlineCart?: OfflineCart | null;
  profile?: StoreProfile;
  store?: Store;
  inputText?: string;
  showExternalPaymentFlow = false;
  isCartBusy: boolean = false;
  isPaymentBusy = false;
  error: any;
  requiresShipmentAddress: boolean = false;
  currencyCode?: string;
  cultureName?: string;
  shoppingBag?: Paginated<Product>;
  currentAdjustmentType: AdjustmentType | undefined;
  showOfflineCartProgress = false;
  discountCodeEnabled = false;
  private counter: Array<string> = [];
  relatedProducts: Array<string> = [];
  directPayment?: PaymentMethod;

  private cartChangeSubscription: Subscription | undefined;
  private offlineCartChangeSubscription: Subscription | undefined;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private storeService: StoreService,
              private scannerService: ScannerService,
              private orderService: OrderService,
              private cartService: CartService,
              private offlineCartService: OfflineCartService,
              private productService: ProductService,
              private paymentService: PaymentService,
              private dialogService: MatDialog,
              private customerService: CustomerService,
              private translateService: TranslateService,
              private bottomSheet: MatBottomSheet,
              private dialog: MatDialog,
              private adjustmentService: AdjustmentTypeService,
              private toastr: ToastrService,
  ) {
  }

  async ngOnInit() {
    if (!this.router.url.includes('/store/')) {
      return;
    }
    //  this.currentAdjustmentType = this.cartService.adjustmentselected
    const storeHandle = this.route.parent!.firstChild!.snapshot.paramMap.get('id')!;
    this.store = await this.storeService.getStore(storeHandle);
    const store = this.store;
    this.profile = this.store.storeProfile;

    const directPaymentOption = await this.paymentService.getDirectPaymentOption(this.store.id);
    if (directPaymentOption) {
      this.directPayment = directPaymentOption;
    }

    const cartParam = this.route.snapshot.queryParams['orderId'];
    const customerIdParam = this.route.snapshot.queryParams['custId'];

    if (cartParam !== undefined) {
      await this.customerService.customerAnonymousAuthenticate(customerIdParam);
      const order = await this.orderService.getOrder(customerIdParam, cartParam);
      await this.cartService.setCart(storeHandle, order);
    }

    this.setCart(await this.cartService.getOrCreateLocalCart(storeHandle), store);
    this.offlineCart = this.offlineCartService.getCart(storeHandle);
    this.cartChangeSubscription = this.cartService
      .onCartChanges(storeHandle)
      .subscribe(value => this.setCart(value, store));
    this.offlineCartChangeSubscription = this.offlineCartService
      .onCartChanges(storeHandle)
      .subscribe(value => this.offlineCart = value);
    this.currencyCode = this.store?.currencyCode;
    this.cultureName = this.store?.cultureName;
    this.shoppingBag = await this.productService.getShoppingBagProducts(storeHandle);
    await this.checkAdjustmentType();
    await this.getDiscountSetting();
  }

  ngOnDestroy(): void {
    this.cartChangeSubscription?.unsubscribe();
    this.offlineCartChangeSubscription?.unsubscribe();
  }

  private setCart(cart: Cart, store: Store) {
    this.requiresShipmentAddress = CartUtils.requiresShipmentAddress(store, cart);
    this.cart = cart;
    this.checkForDepositFeeProducts();
  }

  toPrice(price: number): string {
    return PriceUtils.toPrice(price);
  }

  getProductCount(cart?: Cart): number {
    return cart?.orderLines
      .filter(line => line.type === OrderLineType.Product)
      .map(line => line as ProductOrderLine)
      .map(line => line.product.isWeight ? 1 : line.quantity)
      .reduce((sum, q) => sum + q, 0) ?? 0 ?? 0;
  }

  async increase(orderLine: ProductOrderLine) {
    this.isCartBusy = true;
    if (this.store != null) {
      await this.cartService.increaseCount(this.store.handle, orderLine);
    }
    this.isCartBusy = false;
  }

  async decrease(orderLine: ProductOrderLine) {
    if (this.store?.handle == null) {
      return;
    }

    if (orderLine.quantity <= 1 || orderLine.product.isWeight) {
      this.isCartBusy = true;
      if (await this.confirmRemoval()) {
        await this.cartService.remove(this.store.handle, orderLine);
        await this.ngOnInit();
      }
      this.isCartBusy = false;
      return;
    }
    this.isCartBusy = true;
    await this.cartService.decreaseCount(this.store.handle, orderLine);
    this.isCartBusy = false;
  }

  async initializePayment(method: PaymentMethod) {
    this.scannerService.scanner?.stopScan();
    if (this.cart?.adjustmentTypeId == undefined && this.store?.adjustmentTypes != undefined && this.store?.adjustmentTypes.length > 0) {
      return;
    } else {
      this.error = undefined;
      this.isPaymentBusy = true;
      const storeHandle = this.store!.handle;
      let response: InitializePaymentResponse;

      try {
        response = await this.paymentService.initialize(method, this.cart!);
      } catch (errorResponse) {
        try {
          await PaymentService.errorHandler(errorResponse, {
            orderIsPaid: async () => {
              // Order has already been paid; do a full page reload to recover state
              await this.router.navigate(ForegroundPaths.empty());
              await delay(3000);
              window.location.reload();
            },
            productNotFound: async () => {
              this.cartService.clearCart(storeHandle);
              await this.router.navigate(ForegroundPaths.paymentFailed());
            },
            dimensionOutOfStock: msg => {
              this.error = msg;
            },
          });
        } catch (error) {
          // Swallow the error. Not ideal; should be refactored.
        }

        this.isPaymentBusy = false;
        return null;
      }

      if (response.identifier === PaymentIdentifier.Adyen) {
        const adyenSession = response.data as AdyenSession;
        const configuration = {
          environment: adyenSession.environment,
          clientKey: adyenSession.clientKey,
          session: {
            id: adyenSession.id,
            sessionData: adyenSession.data
          },
          paymentMethodsConfiguration: {
            card: {
              hasHolderName: true,
              holderNameRequired: true,
              billingAddressRequired: false
            }
          },
          onError: async (error: any, component: any) => {
            component.setStatus('ready');
            let text = await this.translateService.get('DIALOG.PAYMENT.text').toPromise();
            text = text + ' (' + error.name + ')';
            const confirm = await this.translateService.get('DIALOG.PAYMENT.ok').toPromise();
            const title = await this.translateService.get('DIALOG.PAYMENT.title').toPromise();
            const dialogRef = this.dialogService.open(MessageDialog, {
              width: '250px',
              data: {
                text,
                confirm,
                title
              }
            });
            await dialogRef.afterClosed().toPromise();
          },
          onPaymentCompleted: async (result: any, component: any) => {
            if (result.resultCode == 'Refused') {
              component.setStatus('ready');
              let text = await this.translateService.get('DIALOG.PAYMENT.text').toPromise();
              text = text + ' (' + result.resultCode + ')';
              const confirm = await this.translateService.get('DIALOG.PAYMENT.ok').toPromise();
              const title = await this.translateService.get('DIALOG.PAYMENT.title').toPromise();
              const dialogRef = this.dialogService.open(MessageDialog, {
                width: '250px',
                data: {
                  text,
                  confirm,
                  title
                }
              });
              await dialogRef.afterClosed().toPromise();
            } else {
              await this.router.navigate(ForegroundPaths.receipt(response.orderId, ''));
            }
          },
        };
        this.showExternalPaymentFlow = true;
        const checkout = await AdyenCheckout(configuration);
        checkout.create('dropin').mount('#dropin-container');
        return;
      } else if (response.identifier === PaymentIdentifier.Vipps || response.identifier === PaymentIdentifier.Swish || response.identifier === PaymentIdentifier.Mastercard || response.identifier === PaymentIdentifier.Checkout) {
        window.location.href = response.data.url;
      } else if (response.identifier === PaymentIdentifier.Demo) {
        await this.router.navigate(ForegroundPaths.receipt(response.orderId, ''));
      }
      this.isPaymentBusy = false;
      return;
    }
  }

  private async confirmRemoval(): Promise<boolean> {
    const text = await this.translateService.get('DIALOG.REMOVEPRODUCT.content').toPromise();
    const confirm = await this.translateService.get('DIALOG.REMOVEPRODUCT.submit').toPromise();
    const dialogRef = this.dialogService.open(ConfirmDialog, {
      width: '250px',
      data: {
        text,
        confirm,
      }
    });
    const result = await dialogRef.afterClosed().toPromise();
    return result === true;
  }

  isOrderLineValid(orderLine: ProductOrderLine | CampaignOrderLine): boolean {
    return !(orderLine.type === OrderLineType.Product && orderLine?.product?.isWeight && orderLine?.quantity === 0);

  }

  isCartValid(cart?: Cart): boolean {
    if (!cart || !this.store) {
      return false;
    }
    const hasInvalidOrderLines = cart.orderLines.filter(line => !(this.isOrderLineValid(line))).length > 0;
    const requiresShipmentAddress = CartUtils.requiresShipmentAddress(this.store, cart);
    const hasShippingAddress = cart.shippingAddress != null;
    const hasValidShipmentAddress = !requiresShipmentAddress || hasShippingAddress;
    return cart.sum > 0 && !hasInvalidOrderLines && hasValidShipmentAddress;
  }


  async openOfflineBottomSheet(orderLine: OfflineProductOrderLine) {
    const result = await this.bottomSheet.open(AmountsheetDialog, {
      data: orderLine.quantity
    }).afterDismissed().toPromise();
    if (result == null || result ! instanceof Number) {
      return;
    }
    const quantity = result as Number;
    if (this.store?.handle != null) {
      if (quantity === 0) {
        await this.offlineCartService.remove(this.store.handle, orderLine);
      }
      if (quantity > 0) {
        await this.offlineCartService.setCount(this.store.handle, orderLine, result);
      }
    }
    return;
  }

  async openBottomSheet(orderLine: ProductOrderLine) {
    let productNameSelectedForQuantityEdit = orderLine.name;
    this.counter.push(productNameSelectedForQuantityEdit);
    let numberOfTimesQuantityEdit = this.countInArray(this.counter, productNameSelectedForQuantityEdit);
    let quantityBeforeEdit = orderLine.quantity;
    let result = await this.bottomSheet.open(AmountsheetDialog, {
      data: orderLine.quantity
    }).afterDismissed().toPromise();
    if (result < quantityBeforeEdit - 2 || numberOfTimesQuantityEdit > 3) {
      result = await this.dialog.open(QuantityConfirmDialog, {
        data: orderLine.quantity
      }).afterClosed().toPromise();
    }
    if (result == null || typeof result !== 'number') {
      return;
    }
    const quantity = result as number;
    if (this.store?.handle != null) {
      if (quantity === 0) {
        await this.cartService.remove(this.store.handle, orderLine);
        await this.ngOnInit();
      }
      if (quantity > 0) {
        await this.cartService.setCount(this.store.handle, orderLine, result);
      }
    }
    return;
  }

  async openShipmentSheet() {
    const result = await this.bottomSheet.open(ShipmentsheetDialog, {
        data: this.cart?.shippingAddress,
        panelClass: 'full-screen-bottom-sheet',
      },
    ).afterDismissed().toPromise();
    if (result as ShippingAddress && this.cart) {
      this.cart.shippingAddress = result;
      if (this.store?.handle && result != null) {
        await this.cartService.setShipmentAddress(this.store?.handle, result);
      }
    }
  }

  async onFulfillmentSelected(orderLine: ProductOrderLine | CampaignOrderLine, fulfillmentOption: FulfillmentOption) {
    if (orderLine as ProductOrderLine) {
      await this.cartService.setFulfillmentOptions(this.store?.handle!, orderLine as ProductOrderLine, fulfillmentOption);
    }
  }

  async removeOrderLine(orderLine: ProductOrderLine) {
    const confirmed = await this.confirmRemoval();
    if (confirmed && this.store) {
      await this.cartService.remove(this.store.handle, orderLine);
      await this.ngOnInit();
    }
  }


  async openShoppingBags() {
    await this.router.navigate(ForegroundPaths.shoppingBag());
  }

  async addDiscount(discountCode: string | undefined) {
    await this.cartService.setDiscount(this.store?.handle!, discountCode);
  }

  async checkAdjustmentType() {
    let storedAdjustmentTypeId = this.adjustmentService.getStoredTypeId(this.store!.handle);
    if (storedAdjustmentTypeId != null) {
      const adjustmentType = this.store?.adjustmentTypes.find(at => at.id === storedAdjustmentTypeId);
      if (adjustmentType !== undefined) {
        this.currentAdjustmentType = adjustmentType;
      }
    } else {
      if (this.store?.adjustmentTypes != undefined && this.store?.adjustmentTypes.length > 0) {
        await this.requestAdjustment();
      }
    }
  }

  async requestAdjustment() {
    const result = await this.dialogService.open(AdjustmentTypeDialog, {
      width: '250px',
      data: this.store?.adjustmentTypes,
      disableClose: !this.cart?.adjustmentTypeId,
    }).afterClosed().toPromise();

    if (!result) {
      return;
    }

    const selected = result as AdjustmentType;
    await this.cartService.setAdjustmentType(this.store!.handle!, selected!);
    await this.cartService.refreshCart(this.store!.handle);
    this.setCart(await this.cartService.getOrCreateLocalCart(this.store!.handle), this.store!);
    this.currentAdjustmentType = selected!;
  }

  getAdjustmentTypeTitle() {
    if (this.currentAdjustmentType?.name == 'TakeAway') {
      return 'Ta med';
    } else if (this.currentAdjustmentType?.name == 'EatIn') {
      return 'Spis her';
    }
    return undefined;
  }

  async tryCartConnect() {
    this.showOfflineCartProgress = true;
    let offlineCart = this.offlineCartService.getCart(this.store!.handle!);

    let products: Product[] = [];

    try {
      for (let orderLine of offlineCart!.orderLines) {
        let product = await this.productService.findByBarcode(this.store!.handle!, orderLine.dimension.barcode);
        products.push(product);
      }

      await this.cartService.addOfflineBatch(this.store!.handle!, products, offlineCart!.orderLines!);
      await this.offlineCartService.clearCart(this.store!.handle!);
    } catch (e) {

    }
    this.showOfflineCartProgress = false;
  }

  countInArray(array: string | any[], what: any) {
    var count = 0;
    for (var i = 0; i < array.length; i++) {
      if (array[i] === what) {
        count++;
      }
    }
    return count;
  }

  private async getDiscountSetting() {
    try {
      this.discountCodeEnabled = await this.storeService.isDiscountCodeEnabled(this.store!.handle);
    } catch (error) {
      if (error.status == 404) {
        this.discountCodeEnabled = false;
      } else {
        throw error;
      }
    }
  }

  async goPayment() {
    if (this.cart && await this.cartService.getCartCount(this.cart) !== '0') {
      await this.router.navigate(ForegroundPaths.cartPayment());
    } else {
      this.toastr.warning(
        await this.translateService.get('CART.empty').toPromise(),
        undefined,
        {timeOut: 3000, easeTime: 100, positionClass: 'toast-top-center'},
      );
    }
  }

  async agecheck() {
    await this.router.navigate(ForegroundPaths.ageLimit());
  }

  resetForm() {
    this.showExternalPaymentFlow = false;
  }

  checkForDepositFeeProducts() {
    const relatedProducts: string[] = [];
    this.cart?.orderLineRelations.forEach(function (value) {
      relatedProducts.push(value.relatedOrderLineId);
    });
    this.relatedProducts = relatedProducts;
  }
}
