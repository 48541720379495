<div *ngIf="addedOrderLine != null" class="cart_floating-container">
  <span class="cart-header">{{ 'CART.added' | translate }}</span>
  <app-cart-item [orderLine]="addedOrderLine"
                 [isBusy]="isCartBusy"
                 [profile]="profile"
                 [dropShadow]="true"
                 (quantityClicked)="openBottomSheet($event)"
                 [cultureName]="cultureName"
                 [currencyCode]="currencyCode">
  </app-cart-item>
</div>

<div *ngIf="addedOfflineOrderLine != null" class="cart_floating-container">
  <span class="cart-header">{{ 'CART.added' | translate }}</span>
  <app-offline-cart-item [orderLine]="addedOfflineOrderLine"
                         [isBusy]="isCartBusy"
                         (quantityClicked)="openOfflineBottomSheet($event)"
                         [dropShadow]="true">
  </app-offline-cart-item>
</div>

<div class="root" [ngStyle]="{backgroundColor: profile?.primaryColor}">
  <div class="cart-shopping"
       [ngClass]="{'cart-shopping--small': directPayment?.logoUrl}"
       [ngStyle]="{stroke: profile?.primaryColor, backgroundColor: profile?.primaryTextColor}"
       (click)="openCart()">
    <div class="cart-shopping-icon-wrapper">
      <svg viewBox="0 0 100 100" class="cart-shopping-icon">
        <path class="st0" d="M33.7,34.5c0,0,6.6-2.6,6.8,6.4c0,0,0.8,15.6,5.7,15.8h16.2"/>
        <path class="st0" d="M40.5,40.8h24.9c0,0-1.5,10.1-6.1,10.3H42.2"/>
        <circle class="st0" cx="44.9" cy="62" r="2.8"/>
        <circle class="st0" cx="58.6" cy="62" r="2.8"/>
      </svg>
    </div>
    <div class="product-quantity">
      <div class="product-quantity-number">{{cartCount}}</div>
    </div>
  </div>

  <div class="cart-price" [ngStyle]="{color: profile?.primaryTextColor}" (click)="openCart()">
    <div class="cart-title-text">{{ 'CART.title' | translate }}:</div>
    <div class="cart-price-total">{{ cartSum | currency:currencyCode:'symbol-narrow': '':cultureName}}
    </div>
  </div>

  <div>
    <button *ngIf="directPayment?.logoUrl" (click)="openPayment()" class="cart-direct-payment-button">
      <img class="cart-direct-payment-logo" [src]="directPayment?.logoUrl" [alt]="directPayment?.name" width="120" height="50">
    </button>
    <button *ngIf="!directPayment?.logoUrl"
            class="cart-pay-now-button"
            [ngStyle]="{backgroundColor: profile?.primaryTextColor, color: profile?.primaryColor}"
            (click)="openPayment()">
      {{ 'CART.paymentMethod' | translate }}
    </button>
  </div>
</div>
